export const basicPaths = [
  {
    paths: ['table', 'tabella'],
    pageUrl: '/table'
  },
  {
    paths: ['table-ajax'],
    pageUrl: '/table-ajax'
  },
  {
    paths: ['survey', 'survey/:id'],
    pageUrl: '/survey'
  },
  {
    paths: ['todo'],
    pageUrl: '/todo'
  },
  {
    paths: ['form'],
    pageUrl: '/form'
  },
  {
    paths: ['terapia'],
    pageUrl: '/terapia'
  },
  {
    paths: ['agenda'],
    pageUrl: '/agenda'
  },
  {
    paths: ['/'],
    pageUrl: '/healthcare/person',
    options: {
      replace: true
    }
  },
  {
    paths: ['anagrafica'],
    pageUrl: '/anagrafica'
  },
  {
    paths: ['anagrafica/more'],
    pageUrl: '/anagrafica2'
  },
  // USER
  {
    paths: ['user'],
    pageUrl: '/auth/user'
  },
  {
    paths: ['user/create'],
    pageUrl: '/auth/user/create'
  },
  {
    paths: ['user/update/:id'],
    pageUrl: '/auth/user/:id/update'
  },
  // USER-MANAGEMENT
  {
    paths: ['user-management'],
    pageUrl: '/auth/user-management'
  },
  {
    paths: ['user-management/create'],
    pageUrl: '/auth/user-management/create'
  },
  {
    paths: ['user-management/update/:id'],
    pageUrl: '/auth/user-management/:id/update'
  },
  // ANAGRAPHIC
  {
    paths: ['anagraphic'],
    pageUrl: '/anagraphic'
  },
  {
    paths: ['anagraphic/create'],
    pageUrl: '/anagraphic/create'
  },
  {
    paths: ['anagraphic/update/:id'],
    pageUrl: '/anagraphic/:id/update'
  },
  // INSTANCE
  {
    paths: ['instance'],
    pageUrl: '/auth/instance'
  },
  {
    paths: ['instance/create'],
    pageUrl: '/auth/instance/create'
  },
  {
    paths: ['instance/update/:id'],
    pageUrl: '/auth/instance/:id/update'
  },
  // PERMISSION
  {
    paths: ['permission'],
    pageUrl: '/auth/permission'
  },
  {
    paths: ['permission/create'],
    pageUrl: '/auth/permission/create'
  },
  {
    paths: ['permission/update/:id'],
    pageUrl: '/auth/permission/:id/update'
  },
  // ROLE
  {
    paths: ['role'],
    pageUrl: '/auth/role'
  },
  {
    paths: ['role/create'],
    pageUrl: '/auth/role/create'
  },
  {
    paths: ['role/update/:id'],
    pageUrl: '/auth/role/:id/update'
  },
  {
    paths: ['role/clone/:id'],
    pageUrl: '/auth/role/:id/clone'
  },
  /**
   * HEALTHCARE
   */
  // DASHBOARD
  {
    paths: ['person/dashboard/:id'],
    pageUrl: '/healthcare/dashboard/person/:id'
  },
  // PATIENT
  {
    paths: ['person'],
    pageUrl: '/healthcare/person'
  },
  {
    paths: ['person/create', 'person/create/:serviceName'],
    pageUrl: '/healthcare/person/create/:serviceName?'
  },
  {
    paths: ['person/update/:id', 'person/update/:id/:serviceName'],
    pageUrl: '/healthcare/person/:id/update/:serviceName?'
  },
  // HEALTH-DATA
  {
    paths: ['person/:id/health-data', 'person/:id/health-data/:serviceName'],
    pageUrl: '/healthcare/health-data/:id/:serviceName?'
  },
  // QUESTION
  {
    paths: ['question'],
    pageUrl: '/healthcare/question'
  },
  {
    paths: ['question/create'],
    pageUrl: '/healthcare/question/create'
  },
  {
    paths: ['question/update/:id'],
    pageUrl: '/healthcare/question/:id/update'
  },
  // MEDICAL-HISTORY
  {
    paths: ['person/:id/medical-history'],
    pageUrl: '/healthcare/person/:id/medical-history'
  },
  {
    paths: ['person/:idPerson/medical-history/create'],
    pageUrl: '/healthcare/person/:idPerson/medical-history/create'
  },
  {
    paths: ['person/:idPerson/medical-history/update/:id'],
    pageUrl: '/healthcare/person/:idPerson/medical-history/:id/update'
  },
  // CLINICAL-EXAM
  {
    paths: ['person/:id/clinical-exam'],
    pageUrl: '/healthcare/person/:id/clinical-exam'
  },
  {
    paths: ['person/:idPerson/clinical-exam/create'],
    pageUrl: '/healthcare/person/:idPerson/clinical-exam/create'
  },
  {
    paths: ['person/:idPerson/clinical-exam/update/:id'],
    pageUrl: '/healthcare/person/:idPerson/clinical-exam/:id/update'
  },
  // TERAPY SCHEDULE
  {
    paths: ['person/:id/schedule/terapy'],
    pageUrl: '/healthcare/person/:id/schedule/terapy'
  },
  {
    paths: ['person/:idPerson/schedule/terapy/create'],
    pageUrl: '/healthcare/person/:idPerson/schedule/terapy/create'
  },
  {
    paths: ['person/:idPerson/schedule/terapy/update/:id'],
    pageUrl: '/healthcare/person/:idPerson/schedule/terapy/:id/update'
  },
  {
    paths: ['person/:idPerson/schedule/terapy/clone/:id'],
    pageUrl: '/healthcare/person/:idPerson/schedule/terapy/:id/clone'
  },
  // AID
  {
    paths: ['aid'],
    pageUrl: '/healthcare/aid'
  },
  {
    paths: ['aid/create'],
    pageUrl: '/healthcare/aid/create'
  },
  {
    paths: ['aid/update/:id'],
    pageUrl: '/healthcare/aid/:id/update'
  },
  // ACTIVITY
  {
    paths: ['activity'],
    pageUrl: '/healthcare/activity'
  },
  {
    paths: ['person/:id/activity'],
    pageUrl: '/healthcare/person/:id/activity'
  },
  {
    paths: ['person/:idPerson/activity/:idActivity/:activityName/create'],
    pageUrl: '/healthcare/person/:idPerson/activity/:idActivity/:activityName/create'
  },
  {
    paths: ['person/:idPerson/activity/view-details/page/:idActivity'],
    pageUrl: '/healthcare/person/:idPerson/activity/:idActivity/view-details'
  },
  {
    paths: ['activity/view-details/page/:idActivity'],
    pageUrl: '/healthcare/activity/:idActivity/view-details'
  },
  {
    paths: ['activity/:idActivity/open-quiz-question/view-details/page/:idQuizQuestion'],
    pageUrl: '/healthcare/activity/:idActivity/open-quiz-question/:idQuizQuestion/view-details'
  },
  {
    paths: ['person/:id/activity/:isScheduled'],
    pageUrl: '/healthcare/person/:id/activity/:isScheduled'
  },
  // QUESTIONNAIRE
  {
    paths: ['person/:idPerson/questionnaire/:type/create'],
    pageUrl: '/healthcare/person/:idPerson/questionnaire/:type/create'
  },
  {
    paths: ['person/:idPerson/questionnaire/update/:idQuestionnaire'],
    pageUrl: '/healthcare/person/:idPerson/questionnaire/:idQuestionnaire/update'
  },
  {
    paths: ['person/:idPerson/questionnaire/:type'],
    pageUrl: '/healthcare/person/:idPerson/questionnaire/:type'
  },
  // FILE
  {
    paths: ['person/:id/file'],
    pageUrl: '/healthcare/person/:id/file'
  },
  {
    paths: ['person/:idPerson/file/create'],
    pageUrl: '/healthcare/person/:idPerson/file/create'
  },
  {
    paths: ['person/:idPerson/file/update/:id'],
    pageUrl: '/healthcare/person/:idPerson/file/:id/update'
  },
  // QUESTIONNAIRE-CONFIG
  {
    paths: ['person/:idPerson/questionnaire-config'],
    pageUrl: '/healthcare/person/:idPerson/questionnaire-config'
  },
  // HOME-NOTIFICATION
  {
    paths: ['home-notification'],
    pageUrl: '/pusher/home-notification'
  },
  {
    paths: ['home-notification/create'],
    pageUrl: '/pusher/home-notification/create'
  },
  {
    paths: ['home-notification/update/:id'],
    pageUrl: '/pusher/home-notification/:id/update'
  },
  // USER LOG
  {
    paths: ['logs'],
    pageUrl: '/logs'
  },
  {
    paths: ['logs/page/:id'],
    pageUrl: '/logs/:id'
  },
  // TALE
  {
    paths: ['tale'],
    pageUrl: '/healthcare/tale'
  },
  {
    paths: ['tale/create'],
    pageUrl: '/healthcare/tale/create'
  },
  {
    paths: ['tale/update/:id'],
    pageUrl: '/healthcare/tale/:id/update'
  },
  // QUIZ
  {
    paths: ['tale/quiz/page/:id'],
    pageUrl: '/healthcare/tale/:id/quiz'
  },
  // QUIZ-QUESTION
  {
    paths: ['tale/:idTale/quiz/:idQuiz/quiz-question/create'],
    pageUrl: '/healthcare/tale/:idTale/quiz/:idQuiz/quiz-question/create'
  },
  {
    paths: ['tale/:idTale/quiz/:idQuiz/quiz-question/update/:id'],
    pageUrl: '/healthcare/tale/:idTale/quiz/:idQuiz/quiz-question/:id/update'
  },
  // SUMMARY
  {
    paths: ['summary/:id/view-details/page'],
    pageUrl: '/healthcare/summary/:id/view-details'
  }
]
